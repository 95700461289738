export const linkedinText = `Sharing my breath 🌬️✨

I am excited to reveal the unique visualization of my breath, crafted as part of the A Thousand Breaths (ATB) project – a visionary collaboration between LoPh+ and MCS! 🌟✨
In alignment with the De.RtB_A Decentralized Right to Breathe initiative, ATB is actively championing the awareness of the significance each breath holds.🌱 🫁

De.RtB champions a future where web3 tools and innovations are used to decentralize justice and empower local communities in their daily endeavors to create a healthy atmosphere.
 I invite you to stand with me in supporting this noble cause, contributing to the creation of a more breathable and sustainable future together! 🌬️💙
Recent findings from the World Health Organization (WHO) underscore the urgency of our mission. Shockingly, 99 percent of the world’s population breathes air that jeopardizes their health. As WHO Director of the Department of Public Health and Environment, Maria Neira, rightfully emphasizes, "After surviving a pandemic, it is unacceptable to still have 7 million preventable deaths and countless lost years of good health due to air pollution."
How can you make a difference? Reach out to LoPh+, become an integral part of our community by sharing your stories, knowledge, skills, or vision, or lend your support to the project through a donation.
Let's collaboratively engineer a better environmental and social atmosphere on our shared planet. Every breath deserves to be cherished, and together, we can transcend challenges and breathe freely in a healthier world.
#AThousandBreaths #BreathVisualization #DeRtB_A #BreatheFreely #ATBCommunity

Connect with MCS Datalabs for more information and exciting updates!`;

export const twitterText = "Excited to share my breath's visualization from A Thousand Breaths (ATB) - a collab between LoPh+ and MCS, championing breath awareness for a healthier atmosphere; let's engineer a better world together - connect with Loph+! 🌬💙 #deRtB #web3 #loph #mcs";
