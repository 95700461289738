<div *ngIf="(breath$| async) as breath else exception" class="container">
    <div *ngIf="(image$ | async) else loading"  class="container">
        <div *ngIf="(metadata$ | async) as metadata" class="info">
            <h2>{{ metadata.description }}</h2>
            <img class="clickable" [src]="metadata.image" (click)="downloadImage(metadata)" alt="NFT Image">
            <div class="icons-container">
              <div class="icon-container">
                <a (click)="downloadImage(metadata)" class="clickable">
                  <img class="icon-size" ngSrc="./assets/icons/download.svg" alt="Download Icon" height="25" width="25">
                  <span class="icon-text">Download</span>
                </a>
              </div>
              <div class="icon-container">
                <div style="position: relative; display: inline-block;">
                  <a (click)="toggleShare()" class="clickable">
                    <img class="icon-size" ngSrc="./assets/icons/share.svg" alt="Share Icon" height="25" width="25">
                    <span class="icon-text">Share</span>
                  </a>

                  <div *ngIf="share" class="share-links">
                    <a [attr.href]="'https://www.linkedin.com/sharing/share-offsite/?url=' + metadata.image + '&summary=' + encodeURIComponent(linkedinShareText)" target="_blank">
                      <img class="icon-size" ngSrc="./assets/icons/linkedin-icon.svg" alt="Share on LinkedIn Icon" height="25" width="25">
                      <span class="share-links-text">Share on LinkedIn</span>
                    </a>

                    <a [attr.href]="'https://twitter.com/intent/tweet?url=' + metadata.image  + '&text=' + encodeURIComponent(twitterText)" target="_blank">
                      <img class="icon-size" ngSrc="./assets/icons/x-icon.svg" alt="Share on X" height="25" width="25">
                      <span class="share-links-text">Share on X</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
                <p>Token  ID:  {{ tid }}</p>
<!--            <p>Token  ID:  {{ breath.data.rfid }}</p>-->
<!--            <p>Generated on: {{ breath.data.date_t }}</p>-->
            <hr>
            <div class="social">
                <div class="social__block">
                    <div class="social__title">
                        <h2>LoPh:</h2>
                    </div>
                    <a href="https://www.linkedin.com/company/logische-phantasie-lab/" target="_blank">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M24.4492 24.451H20.8933V18.8801C20.8933 17.5518 20.8693 15.8414 19.0435 15.8414C17.1914 15.8414 16.9078 17.2885 16.9078 18.7825V24.4506H13.352V12.9975H16.7656V14.5628H16.8116C17.1533 13.9787 17.6471 13.4981 18.2403 13.1723C18.8334 12.8465 19.5039 12.6878 20.1802 12.7129C23.7844 12.7129 24.4492 15.084 24.4492 18.1683V24.451ZM9.33937 11.4316C8.93116 11.4317 8.5321 11.3107 8.19264 11.084C7.85318 10.8573 7.58858 10.5349 7.4323 10.1578C7.27603 9.78065 7.23509 9.36563 7.31467 8.96522C7.39425 8.56482 7.59078 8.197 7.87939 7.9083C8.16801 7.6196 8.53575 7.42297 8.93611 7.3433C9.33646 7.26362 9.75146 7.30447 10.1286 7.46067C10.5057 7.61688 10.8281 7.88143 11.0549 8.22086C11.2817 8.56029 11.4027 8.95937 11.4027 9.36761C11.4027 9.91494 11.1854 10.4399 10.7984 10.8269C10.4115 11.214 9.88665 11.4315 9.33937 11.4316V11.4316ZM11.1173 24.4506H7.55774V12.9975H11.1178L11.1173 24.4506ZM26.2202 4.00156H5.77058C5.54054 3.99876 5.3122 4.04137 5.09864 4.12695C4.88508 4.21253 4.6905 4.33939 4.52604 4.50028C4.36157 4.66117 4.23045 4.85291 4.14019 5.06455C4.04992 5.27618 4.00228 5.50354 4 5.73361V26.2678C4.00546 26.7323 4.19506 27.1756 4.52713 27.5003C4.85921 27.8251 5.3066 28.0048 5.77104 27.9998H26.2202C26.6859 28.0061 27.135 27.8272 27.4687 27.5024C27.8025 27.1776 27.9936 26.7335 28 26.2678V5.72993C27.9933 5.26452 27.8021 4.82082 27.4683 4.49643C27.1345 4.17204 26.6856 3.99354 26.2202 4.00018"/>
                        </svg>
                    </a>
                    <a href="https://www.paypal.com/paypalme/LogischePhantasie" target="_blank">
                      <img class="social-icon" ngSrc="./assets/icons/paypal_magenta.svg" alt="Share Icon" height="30" width="30">
                    </a>
                    <p><a href="https://lo-ph.agency" target="_blank">lo-ph.agency</a></p>
                    <div style="position: relative; display: inline-block;">
                      <a href="https://www.lo-ph.agency" target="_blank">
                        <img class="partner-logo"  ngSrc="./assets/icons/logo_lpl.svg" alt="logische phantasie lab" height="30" width="30">
                      </a>
                      <a href="https://www.mcs-datalabs.com/" target="_blank">
                        <img class="mcs-logo"  ngSrc="./assets/icons/logo_mcs.svg" alt="MCS Datalabs GmbH" height="30" width="30">
                      </a>
                      <a href="https://www.lo-ph.agency/dertb" target="_blank">
                        <img class="partner-logo"  ngSrc="./assets/icons/logo_rtb.svg" alt="rtb" height="30" width="30">
                      </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="traits">
            <div>
                <bfs-progressbar title="Carbon" [progress]="breath.data.ref1.CO2" [breathProgress]="breath.data.breath.CO2" [total]="5000"></bfs-progressbar>
                <bfs-progressbar title="Equivalent Carbon" [progress]="breath.data.ref1.eCO2" [breathProgress]="breath.data.breath.eCO2" [total]="14000"></bfs-progressbar>
                <bfs-progressbar title="Humidity" [progress]="breath.data.ref1.hum" [breathProgress]="breath.data.breath.hum" [total]="100"></bfs-progressbar>
                <bfs-progressbar title="Ethanol" [progress]="breath.data.ref1.ethanol" [breathProgress]="breath.data.breath.ethanol" [total]="20000"></bfs-progressbar>
                <bfs-progressbar title="Hydrogen" [progress]="breath.data.ref1.h2" [breathProgress]="breath.data.breath.h2" [total]="14000"></bfs-progressbar>
                <bfs-progressbar title="Total Volatile Organic Compounds" [progress]="breath.data.ref1.tvoc" [breathProgress]="breath.data.breath.tvoc" [total]="2000"></bfs-progressbar>
                <bfs-progressbar title="Temperature" [progress]="breath.data.ref1.temp" [breathProgress]="breath.data.breath.temp" [total]="50"></bfs-progressbar>
            </div>
            <div *ngIf="(metadata$ | async) as metadata" class="quote">
                <div *ngFor="let att of metadata.attributes">
                    <p [ngClass]="{'author': quoteIncludesAuthor(att.value)}">{{ att.value }}</p>
                </div>
            </div>
        </div>
    </div>
    <ng-template #loading>
        <bfs-loading></bfs-loading>
    </ng-template>
</div>
<ng-template #exception>
    <bfs-error [error]="error"></bfs-error>
</ng-template>

